import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";

import { Route, Routes, useLocation, BrowserRouter } from "react-router-dom";

import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "!style-loader!css-loader!sass-loader!./main.scss";
import CssBaseline from "@material-ui/core/CssBaseline";
import moment from "moment";

import Home from "./pages/home/home";
import Navbar from "./components/navbar/navbar";
import Archery from "./pages/archery/archery";
import JOP from "./pages/archery/jop";
import ArcheryRegistration from "./pages/archery/registration";
import HuntingByPermission from "./pages/hunting/hunting-by-permission";
import Airgun from "./pages/airgun/airgun";
import GHHAMatch from "./pages/airgun/george-hutcheon-memorial-hibernation-match";
import PAMAMatch from "./pages/airgun/pamam";
import Hall from "./pages/hall/hall";
import Membership from "./pages/membership/membership";
import Contact from "./pages/contact/contact";
import Footer from "./components/footer/footer";

moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

ReactGA.initialize("G-42Q2EHNF1X");
const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <div className="main-container">
      <Navbar></Navbar>
      <Routes>
        <Route path="/" exact element={<Home />}></Route>
        <Route path="/archery" exact element={<Archery />}></Route>
        <Route path="/archery/jop" element={<JOP />}></Route>
        <Route
          path="/archery/registration"
          element={<ArcheryRegistration />}
        ></Route>
        <Route path="/airgun" exact element={<Airgun />}></Route>
        <Route
          exact
          path="/airgun/george-hutcheon-hibernation-airgun-match"
          element={<GHHAMatch />}
        ></Route>
        <Route
          exact
          path="/airgun/paul-alexander-memorial-airgun-match"
          element={<PAMAMatch />}
        ></Route>
        <Route path="/hall" element={<Hall />}></Route>
        <Route
          path="/hunting-by-permission"
          element={<HuntingByPermission />}
        ></Route>
        <Route path="/membership" element={<Membership />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route
          element={() => {
            return (
              <div className="page no-margin">
                <div
                  className="page-banner"
                  style={{ backgroundImage: `url(/public/images/404.jpg)` }}
                >
                  <div className="page-banner-text">
                    <div className="is-operational">
                      <h1>Oops! This page doesn't exist here.</h1>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        ></Route>
      </Routes>
      <Footer />
    </div>
  );
};

const domNode = document.getElementById("app");
const root = createRoot(domNode);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
