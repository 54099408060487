import React from 'react';
import AntiBot, { openTo } from '../../components/AntiBot';
import { triggerDownload } from '../../services/firebase';

const IndoorProvincials = () => {
  const heroImage = '/public/images/hall-min.jpg';

  const phone = ['7', '3', '0', '3', '.', '2', '7', '2', '.', '4', '0', '6'];
  return (
    <div className="archery page">
      <div className="page-banner" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="page-banner-text">
          <div className="is-operational">
            <h1>2025 BC Provincial Indoor Championships</h1>
            <h1>April 18 2025 - April 20 2025</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="introduction-block">
          <div className="left-block">
            <div className="location-block">
              <a href="https://maps.app.goo.gl/QkSsy3DndT9GN9s46" target="_blank">
                <div className="location-icon-container">
                  <i className="fa fa-map-marker-alt"></i>
                </div>
                <div className="location-info-container">
                  <p>
                    <span className="heading">Richmond Curling Club</span>
                    <br />
                    5540 Hollybridge Way
                    <br />
                    Richmond, BC
                    <br /> 
                    Canada
                  </p>
                  <p className="directions">
                    Directions <i className="fa fa-caret-right"></i>
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="right-content">
            <div id="waitlist">
              <h2>Waitlist</h2>
              The waitlist has been implemented. <br/><br/>
              You can only get an invitation if your name is on the list!  <br/><br/>
              Most people have already been sent invitations--be sure to monitor your inbox/junk folders. <br/><br/>
              When cancellations are received you may get an invitation right away and only be given a few days to register before we move on to the next person on the list.           

            </div>
            <div className="introduction-text">
              <h2>Venue</h2>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1516.807972165261!2d-123.1465064892631!3d49.174709898585185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54860ad2eb90e2b7%3A0x28a8417798fa1fcc!2s5540%20Hollybridge%20Way%2C%20Richmond%2C%20BC%20V7C%204N3!5e0!3m2!1sen!2sca!4v1739606625908!5m2!1sen!2sca"
                width="100%"
                height="450"
                style={{border:0}}
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
            
            <div id="location">
              <h2>Hosted By Richmond Rod & Gun Club</h2>
              <p>
                Location: richmond Curling Club, 5540 Hollybridge Way, Richmond
              </p>
              <p>
                WA/AC Registered 18m round x 2(1 round each on Saturday and Sunday)
              </p>
              
            </div>
            <div id="schedule">
              <h2>Schedule: (Unofficial)</h2>
              <p>
                <u>Friday:</u>
                <br />
                4pm-8pm: Practice, Check-in & Equipment inspection*
                <br />
                <br />
                <u>Saturday & Sunday</u>
                <br />
                Line 1: 9AM
                <br />
                Line 2: 1PM
                <br />
                <br />
                Lines will be assigned according to age & equipment categories (to be communicated closer to the event)
                <br />
                (Medal Presentation: Sunday approx 5:00pm)
              </p>
              <p className='disclaimer'>
              *Equipment Inspection will be available on Saturday at 8am and Noon for those unable to check-in on Friday. Every attempt should be made to go through equipment inspection on Friday to avoid potential shooting delays.
              </p>
              <p>
              Eligibility Criteria: A resident of BC must be a member of BC Archery in order to compete in a BC Archery event; a participant who is not a resident of BC must be a registrant of Archery Canada, and must must register in a Guest category (see the BC Archery Rules of Shooting) 
              </p>
              <p className='disclaimer'>
              **Guests are not able to register through Interpodia. Please send an email for registration information. (See below for more information about Guest categories)
              </p>
            </div>
            
            <h2>Fees</h2>
              <div className="columns">
              <p className="three-column-cell">Registration Fees:	
                </p>
                <p className="three-column-cell">Until Feb 6
                </p>
                <p className="three-column-cell">Feb 7 - Apr 4
                </p>
                <p className="three-column-cell">Adults: Age 21+ <br></br>(Senior & Master)
                </p>
                <p className="three-column-cell">$85
                </p>
                <p className="three-column-cell">$105
                </p>
                <p className="three-column-cell">Youth: U21 & under <br></br>(Junior & younger)
                </p>
                <p className="three-column-cell">$75
                </p>
                <p className="three-column-cell">$90
                </p>
                
              </div>
              <div>
              <p>Registration deadline: April 4th</p>
                <p>Cancellation & Refunds allowed until April 4th with a $10 per participant cancellation fee. 
                  No refunds after April 4th. To cancel, 
                  <a href='mailto:accounting@bcarchery.ca?subject=Cancellation%20request%3A%20BC%20Indoors'> click here to send an email</a></p>
                <h2>Hotel</h2>
                <p>
                Host Hotel:<br />
                Executive Hotel, Vancouver Airport, 7311 Westminster Hwy.<br />
                10 min walk to venue--650m<br />
                Rates from $209/night to $299/night<br />
                <img src='public/images/provincials.png' style={{width:"100%"}}></img>
                For more details and booking instructions, <a href='https://drive.google.com/file/d/1uGkdR-8_RA49OiLjeaZqAFjhNa4vBk_0/view?usp=drive_link'>click here for the flyer.<br /></a>
                </p>
                <p>The hotel can be found below:</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2608.5199285952963!2d-123.14787862351763!3d49.170421871376234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54860acc35952845%3A0x94153e1e8b71cb3!2sExecutive%20Hotel%20Vancouver%20Airport!5e0!3m2!1sen!2sca!4v1740030578058!5m2!1sen!2sca" 
                width="100%" 
                height="450" 
                style={{ border:0 }}
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
                </iframe>
                <h2>Reservations</h2>
                <p>
                For reservations, guests may call our hotel locally at: <br/>
                604-278-5555<br/><br/>
                or toll-free at: <br/>
                1-800-663-2878 <br/><br/>
                Quote the group name “2025 BC Archery Indoor Championships” or group code “106812”.<br/>
                <br/>
                Please book <b>before March 15th</b> as that is the cutoff date, after that the discount will no longer be available.

                </p>
                <h2>Parking</h2>
                <p>There is on-site PAY parking:<br/>
                $10/day<br/>
                $3/hr<br/>
                </p>
                <h2>Banquet</h2>
                <p>The club intends to hold a banquet on Saturday evening. Details are pending.</p>
                <h2>Guest Medals</h2>
                <p>Per the BC Archery Rules of Shooting, there are 2 age classes ("Adult" 18+, and "Youth" 17 & younger) and 3 equipment divisions (Compound, Recurve, Barebow).  <br/><br/>
                  Guest medals are awarded to only the first place in each Guest category. <br/><br/>
                  Guests are not eligible to be awarded BC Archery Provincial Championship medals.</p>

              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default IndoorProvincials;
