import React from 'react';
import AntiBot, { openTo } from '../../components/AntiBot';
import { triggerDownload } from '../../services/firebase';

const Waiver = () => {
  const heroImage = '/public/images/hall-min.jpg';

  const phone = ['7', '3', '0', '3', '.', '2', '7', '2', '.', '4', '0', '6'];
  const getHallRentalDownloadURL = () => {
    triggerDownload('public/Hall_Rental_Rules_2023.pdf');
  };

  return (
    <div className="archery page">
      <div className="container">
        <div className="introduction-block">
          <div className="left-block">
            <div className="location-block">
            </div>
          </div>
          <div className="right-content">
            <div className="introduction-text">
              <h2>Archery Waiver Form</h2>
              <iframe aria-label='Archery Waver' frameborder="0" style={{ height: "2300px", width: "99%", border: "none" }} src='https://forms.zohopublic.ca/rrgc1/form/ArcheryWaver/formperma/QrL79Y3Xp24hHSUKXhxWgIFQQR_oTP23APi5Itc9hTo'></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Waiver;
