import React from 'react';
import AntiBot, { openTo } from '../../components/AntiBot';
import { triggerDownload } from '../../services/firebase';

const Hall = () => {
  const heroImage = '/public/images/hall-min.jpg';

  const phone = ['7', '3', '0', '3', '.', '2', '7', '2', '.', '4', '0', '6'];
  const getHallRentalDownloadURL = () => {
    triggerDownload('public/Hall_Rental_Rules_2023.pdf');
  };

  return (
    <div className="archery page">
      <div className="page-banner" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="page-banner-text">
          <div className="is-operational">
            <h1>RRGC Hall Rentals</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="introduction-block">
          <div className="left-block">
            <div className="location-block">
              <a href="https://goo.gl/maps/RGBLF6XJJPGDRzA89" target="_blank">
                <div className="location-icon-container">
                  <i className="fa fa-map-marker-alt"></i>
                </div>
                <div className="location-info-container">
                  <p>
                    <span className="heading">RRGC Club Hall</span>
                    <br />
                    7891 Cambie Rd
                    <br />
                    Richmond, BC,
                    <br />
                    Canada
                  </p>
                  <p className="directions">
                    Directions <i className="fa fa-caret-right"></i>
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="right-content">
            <div className="introduction-text">
              <h2>RRGC Club Hall</h2>
              <p>
                Our Club House is available for rent! We feature a spacious, well-maintained and cozy club hall that
                will accommodate up to 149 people. With washrooms on premises, a private parking lot and close proximity
                to public transport, we are sure to be able to accomodate your next event here.{' '}
              </p>
              <p className="disclaimer no-margin">Rules and Regulations apply. </p>
              <p>
                Please contact Kathy Stephens at{' '}
                <a href="javascript:void(0)" onClick={openTo(phone, 'tel')}>
                  <AntiBot text={phone} />
                </a>{' '}
                for any questions on facilities, availability and booking. 
                Rental requests can be sent to <a href="mailto:rentals@richmondrodandgunclub.com">rentals@richmondrodandgunclub.com</a>
              </p>
            </div>
            <div className="generic-benefits">
              <h2>Spacious and well-maintained rustic club hall available for day rental.</h2>
              <div className="benefit-box">
                <div className="benefits">
                  <i className="fas fa-users"></i>
                  <div className="text">149 People Capacity</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-subway"></i>
                  <div className="text">Close to Public Transit</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-parking"></i>
                  <div className="text">On-site Parking Lot</div>
                </div>
              </div>

              <div className="what-you-get">
                <p className="line-with-icon">
                  <i className="fas fa-dollar-sign"></i> $450 per day
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-piggy-bank"></i> $400 Damage Deposit
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-chair"></i> Chairs and Tables Available
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-sink"></i> Kitchen Facilities
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-glass-martini-alt"></i> Dry Bar
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-restroom"></i> Washrooms on site
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-parking"></i> Free Parking
                </p>
                <img className="hall-images" src="/public/images/rrgchall1.jpg" alt="" />
                <img className="hall-images" src="/public/images/rrgchall2.jpg" alt="" />
                <img className="hall-images" src="/public/images/rrgchallkitchen.jpeg" alt="" />
              </div>

              <section className="info">
                <h2>Rules and Regulations</h2>
                <p>
                  <a href="javascript:void(0)" onClick={getHallRentalDownloadURL}>
                    <i className="fas fa-file-pdf"></i> Download Rules and Regulations as PDF
                  </a>
                </p>
                <div>
                  <h3>Capacity</h3>
                  <p>
                    Maximum number of people in the Richmond Rod and Gun Club Hall is <strong>149</strong>.
                  </p>
                </div>

                <div>
                  <h3>Fee</h3>
                  <p>
                    $450.00 per day or part thereof, plus GST. The fee is due at time of booking. Cheques are made
                    payable to "The Richmond Rod and Gun Club". Cash is also accepted. A $40.00 fee will be charged for
                    any NSF cheques.
                  </p>
                </div>
                <div>
                  <h3>Damage / Service Deposit</h3>
                  <p>
                    A $400.00 refundable damage/service deposit is required for bookings. Monies will be deducted for the cost of cleaning or repair of the premises and or Hall contents, or late return of the Hall keys, or if the rental contract start or end times are not adhered to. $50.00/hour or portion of an hour will be charged to clean/repair the Hall and or Hall contents or put equipment away. Interest will not be paid on this deposit. The Applicant shall be responsible for any damage resulting from the Event and shall promptly pay any amount owing for repairs in excess of the damage/service deposit. All damage shall be reported immediately to the Richmond Rod And Gun Club.
                  </p>
                </div>
                <div>
                  <h3>Cancellation</h3>
                  <p>No refunds will be issued if less than 14 days notice is given. The Richmond Rod And Gun Club, in its sole discretion reserves the right to deny access, or give notice of cancellation of the contract where said renter misrepresents the use of the facility, or could conceivably jeopardize the safety of the building or its contents.</p>
                </div>
                <div>
                  <h3>To Confirm</h3>
                  <p>The Richmond Rod and Gun Club must receive a signed agreement, printed copy of your insurance certificate, printed copy of Special Occasions License if Liquor will be consumed at the Hall, damage/service deposit and full payment before a booking is confirmed.</p>
                </div>
                <div>
                  <h3>Insurance</h3>
                  <p>All 'Applicants' must purchase and provide proof of comprehensive general liability insurance. They must provide a printed copy which shows the type of insurance, the expiry date, the amount 5,000.000.00 per occurrence. The insurance shall be endorsed to add the <b>“Richmond Rod and Gun Club” and “City of Richmond”</b> as additional insured prior to obtaining keys to the Hall. If the 'Applicants' will be providing alcoholic beverages, the insurance must include 'Host Liquor Liability' coverage.</p>
                </div>
                <div>
                  <h3>Start time</h3>
                  <p>
                    Start time is 7:00 am and rentals must finish by 1:00am. The renter must have the hall cleaned and vacated by the end time on their contract.
                  </p>
                </div>
                <div>
                  <h3>Unauthorized Use</h3>
                  <p>
                    The 'Applicant' shall not permit any persons, groups or organizations not named in this contract to use or occupy the premises without the Richmond Rod and Gun Club written consent.
                  </p>
                </div>
                <div>
                  <h3>Liability</h3>
                  <p>The Richmond Rod and Gun Club and the City of Richmond are not responsible for lost or stolen items.</p>
                </div>
                <div>
                  <h3>Set-up & Clean-up</h3>
                  <p>All groups are required to supply their own dishes, cutlery, glasses, tea towels, special serving dishes, coffee urn, ice and containers. <b>All set up and clean up must be done by the renter.</b> Tables are not to be dragged across the floor. All tables and chairs are to be wiped clean of any food spills and put away where they were found. Chairs stacked in neat piles of 10. The Club Hall, including the kitchen and washrooms are to be left clean and tidy. Failure to do an adequate clean-up will result in additional charges.</p>
                </div>
                <div>
                  <h3>Garbage/Trash</h3>
                  <p>All garbage from your event must be taken with you for proper disposal. Failure to remove all your trash will result in a minimum $ 100.00 surcharge against your Damage/Service Deposit.</p>
                </div>

                <div>
                  <h3>Liquor</h3>
                  <p>If liquor, beer or any other alcoholic beverage will be on or in the Premises a <b>'Special Occasions License'</b> must be purchased and attached to this contract prior to receiving the keys to the Hall. The Applicant must comply with the British Columbia Liquor Control and Licensing Act and Regulation.</p>
                </div>
                <div>
                  <h3>
                    Decorations
                  </h3>
                  <p>
                    The Richmond Rod and Gun Club must approve all decorations. Absolutely no tacks, staples or other means of fastening that will mar the buildings finish are permitted. Use masking tape and strings to hook points as required. Confetti, rice, birdseed, petals etcetera are not permitted in the Hall or on the grounds.
                  </p>
                </div>
                <div>
                  <h3>Security Alarm System</h3>
                  <p>When entering the Hall, use the alarm code provided and push <b>off</b> to disarm the alarm system. When leaving the building properly secure all doors and dead bolts when leaving the building for any reason. Enter the alarm code provided and push <b>on</b> before leaving the building. Doors and dead bolts must be unlocked when the Hall is in use. Failure to reset the alarm with the code provided to you when leaving the building will result in the <b>FOREFETURE</b> of $200.00 and will be charged and deducted from your damage/service deposit.</p>
                </div>
                <div>
                  <h3>Key Return</h3>
                  <p>The key must be returned immediately when you vacate the Hall by pushing the key into the round hole located at the top right corner of the front door, this is done after all the doors have been properly secured. Late key return will result in the <b>FOREFETURE</b> of $100.00 and will be charged and deducted from your damage/service deposit.</p>
                </div>
                <div>
                  <h3>Notes</h3>
                  <p>
                    Do not touch or tamper with, cover or move any animal or fish mounts and displays. Damage while in
                    the possession of the Applicant through willful neglect will be repaired and corrected at the
                    expense of the Applicant.
                  </p>
                </div>
                <h3>No Smoking inside the building. Do not discard cigarette butts on the property.</h3>
                <h3>
                  No use of barbeques, propane tanks or portable stoves of any type to be used inside the building.
                </h3>
                <h3>
                  COVID: All COVID protocols including cleaning and signage will be followed and supplied by the applicant.
                </h3>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hall;
