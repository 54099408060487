import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AIRGUN_HOURS, DATE_KEY_FORMAT, GROUP_SEPARATOR } from '../../services/constants';
import { getSpecialClosures } from '../../services/firebase';
import { NAME, REG_CLOSED } from './pamam';

const mod = (n, m) => {
  return ((n % m) + m) % m;
}

const FORECAST_LENGTH = 7;

const Airgun = () => {
  const heroImage = '/public/images/airgun-hero-min.jpg';
  const membershipUrl = '/membership';
  const registrationsOpen = (moment().diff(REG_CLOSED) < 0);
  const breakingNews = registrationsOpen ? `${NAME} Open. Click here to register!` : '';

  const [hours] = useState(AIRGUN_HOURS);
  const [thisWeek, setThisWeek] = useState([]);
  const [operations, setOperations] = useState();
  const [specialClosures, setSpecialClosures] = useState({});
  const [specialClosure, setSpecialClosure] = useState({});

  const updateSpecialClosures = async () => {
    const closures = await getSpecialClosures();
    setSpecialClosures(closures);
  }
  const updateForecast = () => {
    const today = moment();
    const day = mod(today.day() - 1, 7);
    setOperations(hours[day]);
    const dateKey = moment().format(DATE_KEY_FORMAT);
    let specialClosure = specialClosures[dateKey];
    specialClosure = specialClosure && specialClosure.range.indexOf('airgun') > -1 ? specialClosure : null;
    const startOfWeek = today;

    const thisWeek = [];
    for (let i = 0; i < FORECAST_LENGTH; i++) {
      const day = today.day() - 1;
      //Offset because if day = sunday, day will be 0, causing day to be -1 after the line above this comment is evaluated.
      const DAY_OFFSET=7
      
      const date = startOfWeek.clone().add(i, 'days').format(DATE_KEY_FORMAT);
      const dailyOp = hours[(day + i + DAY_OFFSET) % 7];
      const isTemporarilyClosed = specialClosures[date];
      thisWeek.push({
        ...dailyOp,
        date,
        isTemporarilyClosed: isTemporarilyClosed && isTemporarilyClosed.range.indexOf('airgun') > -1,
        reason: isTemporarilyClosed && isTemporarilyClosed.reason
      })
    }

    setSpecialClosure(specialClosure);
    setThisWeek(thisWeek)
  }


  useEffect(() => {
    updateSpecialClosures();
  }, []);

  useEffect(() => {
    updateForecast();
  }, [specialClosures])

  return (
    <div className="archery page">
      <div className="page-banner" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="page-banner-text">
          <div className="is-operational">
            <h1>RRGC Airgun</h1>
            {operations &&
              <h2 style={{ color: operations.isOpen ? 'white' : 'lightgrey' }}>
                {specialClosure ? 'Closed For Today' : operations.isOpen ? `Open from ${operations.hours} today` : 'Closed Today'}
              </h2>
            }
            <p className="special-closure">{specialClosure ? specialClosure.reason : ''}</p>
            <p className="date">{moment().format('dddd, MMMM Do, YYYY')}</p>
          </div>
        </div>
      </div>
      {breakingNews && (
        <section className="breaking-news happy">
          <div className="container">
            <h3><a href="/airgun/paul-alexander-memorial-airgun-match">{breakingNews}</a></h3>
          </div>
        </section>
      )}
      <div className="container">
        <div className="introduction-block">
          <div className="left-block">
            <div className="location-block">
              <a href="https://g.page/rrgcarcheryandairgun?share" target="_blank">
                <div className="location-icon-container">
                  <i className="fa fa-map-marker-alt"></i>
                </div>
                <div className="location-info-container">
                  <p>
                    <span className="heading">Archery & Airgun Range</span>
                    <br />
                    7400 River Rd Suite 140
                    <br />
                    Richmond, BC,
                    <br />
                    Canada
                  </p>
                  <p className="directions">
                    Directions <i className="fa fa-caret-right"></i>
                  </p>
                </div>
              </a>
            </div>
            <div className="hours" >
              {thisWeek.map((entry, index) => (
                <div className={`hours-day ${index === 0 ? 'current' : ''} ${entry.isTemporarilyClosed || !entry.isOpen ? 'closed' : ''}`}>
                  <div className="day">
                    {entry.label}
                    <div className='date'>{entry.date}</div>
                  </div>
                  <div className="opening-hours">
                    {entry.isTemporarilyClosed ? 'Temporarily Closed' : (
                    //Are hours in an array? If TRUE map through each hour 
                    Array.isArray(entry.hours) ? ( entry.hours.map((hour, i) => 
                    {
                        //Splits the hour string at ':' to seperate type and time
                        const parts = hour.split(':');
                        //Extract the type before ':' and trim any trailing spaces
                        const type = parts[0];
                        //Extract the time after ':', join the rest of the parts with ':' and trim trailing spaces
                        const time = parts.slice(1).join(':').trim();
                        //Determine whether a line break should be added before the current group
                        const shouldAddBreak = type === GROUP_SEPARATOR && i > 0;

                        //Return a div element for each hour
                        return (
                          <div key={i}>
                            {shouldAddBreak ? <br /> : null}
                            {type && <span>{type}:</span>}
                            <br />
                            {time}
                          </div>);
                    })
                    ) : (
                      //If FALSE then render as a single div
                      <div>{entry.hours}</div> )
                    )}
                    {entry.isTemporarilyClosed && (
                      <div className='reason'>{entry.reason}</div>
                    )}
                  </div>
                </div>
              ))}
              <p className="disclaimer">Hours subject to change depending on Range Officer availability.</p>
              <p className='disclaimer'>For appointments, please contact Robert at <a href='mailto:robertreid17498@gmail.com'>robertreid17498@gmail.com</a></p>
              
              <p className="disclaimer">Check our socials for daily updates!<br></br>
              <a href="https://www.instagram.com/richmondrodandgunclub" target="_blank" aria-label="RRGC Archery Instagram">
                <i className="fa fa-instagram" aria-hidden="true" /> Instagram
              </a></p>
            </div>
            <br></br>
            <div className="Calendar">
            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FVancouver&showPrint=0&mode=AGENDA&src=ODI5YmE4ZDg0Zjc1N2M1NGQ2MzFiZTQxYzNlM2ZlZGY5ODFhYTAwY2Y2MjViNzYyZTQwMzc0N2ZkNGIzN2YzMUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23E4C441" style={{border:'solid 1px #777'}} width="100%" height="600" frameborder="0" scrolling="no"></iframe>
            <p>Range hours may change, please add this calendar to your phone or computer to stay up to date with the latest schedule. </p>
              <p>For appointments, please contact Robert at <a href='mailto:robertreid17498@gmail.com'>robertreid17498@gmail.com</a></p>
</div>
          </div>
          <div className="right-content">
            <div className="introduction-text">
              <h2>Richmond Rod & Gun Club Airgun Range</h2>
              <p>Welcome to the Richmond Rod & Gun Archery and Airgun Indoor Range!</p>
              <p>We are a target shooting club focusing on Olympic-style 10m air pistol and air rifle disciplines.</p>
              <p>
                The main goals of the Airgun Range are to support and encourage all experienced competitive shooters
                in honing their skills at our ten position range, and to share knowledge while building camaraderie
                among those who enjoy target sports.
              </p>
              <p>
                We also wish to encourage those that are new to the sport to join us in developing an understanding of
                marksmanship. Please note that we request interested individuals familiarize themselves with the style
                of shooting we do, before coming to the range.
              </p>
              <p> Please follow the links below to learn about the 10m shooting sport.</p>
              <ul>
                <li>
                  <a href="https://en.wikipedia.org/wiki/10_meter_air_rifle">10m air rifle Wikipedia</a>
                </li>
                <li>
                  <a href="http://www.issf-sports.org/theissf/academy/e_learning/rifle.ashx">10m air rifle</a>
                </li>
                <li>
                  <a href="http://www.pyramydair.com/blog/2008/01/10-meter-rifle-part-1/">10m air rifle</a>
                </li>
                <li>
                  <a href="https://en.wikipedia.org/wiki/10_meter_air_pistol">10m air pistol Wikipedia</a>
                </li>
              </ul>
              <h3>We now accept contactless payment options!</h3>
              <div className="columns">
              <p className="two-column-cell">
                  <i className="fa fa-cc-visa"></i> <i className="fa fa-cc-mastercard"></i> <i className="fa fa-cc-amex"></i> Credit
                </p>
                <p className="two-column-cell">
                  <i className="fa fa-money"></i> Cash
                </p>
                <p className="two-column-cell">
                  <i className="fas fa-credit-card"></i> Interac Debit
                </p>
              </div>
            </div>
            <div className="airgun-pricing-text">
              <h2>Pricing</h2>
              <div className="columns">
                <p className="two-column-cell">Members Drop-In
                </p>
                <p className="two-column-cell">$10
                </p>
                <p className="two-column-cell">Non-Members Drop-In
                </p>
                <p className="two-column-cell">$20
                </p>
                <p className="two-column-cell">Gun, 10 Targets, & 100 Pellets 
                <br></br> (All included in pricing)
                </p>
                <p className="two-column-cell">$25
                </p>
                <p className="two-column-cell">Targets - 10x Pistol
                </p>
                <p className="two-column-cell">$2
                </p>
                <p className="two-column-cell">Targets - 10x Rifle
                </p>
                <p className="two-column-cell">$1
                </p>
                <p className="two-column-cell">Pellets
                </p>
                <p className="two-column-cell">prices vary
                </p>
                <p className="two-column-cell">Air Cylinder
                </p>
                <p className="two-column-cell">$2
                </p>
                <p className="two-column-cell">CO2 Cartridge
                </p>
                <p className="two-column-cell">$2
                </p>
                <p className="two-column-cell">Personal Coaching
                </p>
                <p className="two-column-cell">$10
                </p>
                <p className="two-column-cell">Coast to Coast (Members & Athletes)<br></br>
                <p className='disclaimer'>Includes 30x pistol or 60x rifle targets and 1 air refill<br></br>
                  First Wednesday every month - first full week
                </p>
                </p>
                <p className="two-column-cell">$15
                </p>
              </div>
              <p className="disclaimer">Prices may change at any given time</p>
            </div>
            <div className="experienced-shooters">
              <h2>Shooters Welcome!</h2>
              <div className="benefit-box">
                <div className="benefits">
                  <i className="fas fa-hand-holding-usd"></i>
                  <div className="text">
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}>$10</span> Drop-in Fee for{' '}
                    <a href={membershipUrl}>Members</a>*
                  </div>
                </div>
                <div className="benefits">
                  <i className="fas fa-check"></i>
                  <div className="text">Equipment Rentals Available</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-users"></i>
                  <div className="text">Licensed Range Officers</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-battery-full"></i>
                  <div className="text">CO2 Fills and Cartridges Available</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-boxes"></i>
                  <div className="text">.177 Lead Pellets Available</div>
                </div>
              </div>

              <div className="what-you-get">
                <p className="line-with-icon">
                  <i className="fas fa-dollar-sign"></i> $10 per session for{' '}
                  <a href={membershipUrl}>RRGC Members</a>, $20 for general public
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-clock"></i> Two Hours per Session
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-check"></i> $5 Rifle / Pistol Rentals
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-battery-full"></i> $2 for CO2
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-boxes"></i> $12 for 500 .177 Lead Pellets
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-parking"></i> Free Parking
                </p>
              </div>
              <p className="disclaimer">* For RRGC Members, $15 for general public</p>
              <section className="info">
                <h3>For your safety, the following rules apply at our range.</h3>
                <p className="line-with-icon">
                  <i className="fas fa-check"></i> Only Air and CO2 powered pellet pistol and rifles designed for
                  target shooting are permitted
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-chalkboard-teacher"></i> All persons at the range must read the full safety
                  rules that are provided on site, and must follow the directions of the Range Officer at all times.
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-user-friends"></i> Each youth (under 15 years of age) must be accompanied by
                  one adult at all times. There must be one adult for each youth present.
                </p>
                <h3>
                  <i className="fas fa-ban"></i> &nbsp; We do not permit the following in our airgun range.
                </h3>
                <p>
                  <ul>
                    <li>Replica Hand Guns</li>
                    <li>BB Guns</li>
                    <li>Airsoft Guns</li>
                    <li>Guns without adjustable sights</li>
                    <li>Any gun that does not fire a .177 calibre lead pellet by compressed air.</li>
                  </ul>
                </p>
                <h2>Safety is our top priority.</h2>
                <p>
                  We strictly enforce gun safety practices. We do not tolerate misuse of any equipment including your
                  own*. Failure to comply with the rules of the range and Range Officer will result in eviction from
                  the premises or prosecution by law.
                </p>
                <p className="disclaimer">
                  * Intentional misuse and/or endangerment of other persons by airguns can lead to prosecution under
                  the Criminal Code of Canada.
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Airgun;